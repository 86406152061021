export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { appDomain },
  } = useRuntimeConfig()

  if (!appDomain || appDomain.startsWith('dev')) return

  const includeRegex =
    /^(?!.*\/(pages|components|composables|layouts|middleware|server|types|config|public|utils|helpers|repository|forms|plugins)\/).*$/

  const bugsnag = useBugsnag()

  bugsnag.addOnError((event: any) => {
    if (
      !event.errors[0].stacktrace.some((frame: { file: string }) =>
        includeRegex.test(frame.file),
      )
    ) {
      return false
    }
  })

  nuxtApp.vueApp.config.errorHandler = (err: any) => {
    bugsnag.notify(err)
  }
})
