import revive_payload_client_YTGlmQWWT5 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_dRvYLh8oYc from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_VTarsGAzgb from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/site/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_pCpUNSlFZe from "/vercel/path0/node_modules/nuxt-vercel-analytics/dist/runtime/plugin.client.mjs";
import i18n_haD6hKZoZr from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_lCxu0ZmxIx from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_6xSY8Eag1D from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_5C7QFp7tsq from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_rsWSYU43NF from "/vercel/path0/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_5taQjcJ3oK from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import _00_cookies_UxGbIYP5Lj from "/vercel/path0/apps/site/plugins/00.cookies.ts";
import _01_api_GIiciWaEWX from "/vercel/path0/apps/site/plugins/01.api.ts";
import _03_lcServices_qQLkq7GlFA from "/vercel/path0/apps/site/plugins/03.lcServices.ts";
import _04_authentication_yr8Zw3IhcY from "/vercel/path0/apps/site/plugins/04.authentication.ts";
import _06_gtm_client_7NTV0XxZro from "/vercel/path0/apps/site/plugins/06.gtm.client.ts";
import _07_getBrowserLanguage_S8Ci00VXAB from "/vercel/path0/apps/site/plugins/07.getBrowserLanguage.js";
import _08_getUserCurrency_iC9uklmOzO from "/vercel/path0/apps/site/plugins/08.getUserCurrency.js";
import _09_strapi_api_LaKIr10H54 from "/vercel/path0/apps/site/plugins/09.strapi-api.js";
import _10_experiment_DuBl1zzse6 from "/vercel/path0/apps/site/plugins/10.experiment.ts";
import actioncable_client_7QyhyAlEbJ from "/vercel/path0/apps/site/plugins/actioncable.client.js";
import analytics_client_mZYnNRQBH8 from "/vercel/path0/apps/site/plugins/analytics.client.js";
import aos_client_rDSYU13oOX from "/vercel/path0/apps/site/plugins/aos.client.ts";
import bugsnag_client_TtQ636hzGt from "/vercel/path0/apps/site/plugins/bugsnag.client.ts";
import chunkReloadImmediate_client_WmYDyno32c from "/vercel/path0/apps/site/plugins/chunkReloadImmediate.client.ts";
import clickOutside_client_Zc9fkH3Rh6 from "/vercel/path0/apps/site/plugins/clickOutside.client.ts";
import maska_client_D8wFsCvpJf from "/vercel/path0/apps/site/plugins/maska.client.ts";
import vee_validate_K3WwmJMPDb from "/vercel/path0/apps/site/plugins/vee-validate.js";
import ssg_detect_O3dFMwF5H0 from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_dRvYLh8oYc,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_client_pCpUNSlFZe,
  i18n_haD6hKZoZr,
  switch_locale_path_ssr_lCxu0ZmxIx,
  route_locale_detect_6xSY8Eag1D,
  i18n_5C7QFp7tsq,
  plugin_rsWSYU43NF,
  plugin_5taQjcJ3oK,
  _00_cookies_UxGbIYP5Lj,
  _01_api_GIiciWaEWX,
  _03_lcServices_qQLkq7GlFA,
  _04_authentication_yr8Zw3IhcY,
  _06_gtm_client_7NTV0XxZro,
  _07_getBrowserLanguage_S8Ci00VXAB,
  _08_getUserCurrency_iC9uklmOzO,
  _09_strapi_api_LaKIr10H54,
  _10_experiment_DuBl1zzse6,
  actioncable_client_7QyhyAlEbJ,
  analytics_client_mZYnNRQBH8,
  aos_client_rDSYU13oOX,
  bugsnag_client_TtQ636hzGt,
  chunkReloadImmediate_client_WmYDyno32c,
  clickOutside_client_Zc9fkH3Rh6,
  maska_client_D8wFsCvpJf,
  vee_validate_K3WwmJMPDb,
  ssg_detect_O3dFMwF5H0
]